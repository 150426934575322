//Scripts
import React from 'react';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import { toDisplayDateTime } from '../utils/dateTimeUtils'
import { getUserDisplay } from '../utils/businessUtils'
import cachTransactionTypes from './cashRegister/cashTransactionTypes.json'
import { getAquiredCustomerDisplay } from './../utils/businessUtils.js'
import paymentTypes from './bookingList/paymentTypes.json'

//CSS
import './accounting.css';

function Accounting({allTransactions, specificAction, specificAccountingPdf, reload }) {
    const { t } = useTranslation();

    const specificSubject = (payment) => {
        if (payment["cash_type_id"]) {
            const cashTransactionType = cachTransactionTypes.find(item => item.id === payment["cash_type_id"]);
            return t(cashTransactionType.translationCode);
        } else if (payment["type_id"]) {
            const paymentType = paymentTypes.find(item => item.id === payment["type_id"]);
            if (paymentType) {
                return t(paymentType.label);
            } else {
                return "";
            }
        } else {
            return "";
        }
    }

    const removeCashCounting = (cashCountingId) => {
        axios.post('/api/therapist/cash-transactions.php?action=remove-cash-counting', { cashCountingId })
        .then(() => {
            reload()
        })
        .catch(error => {
            console.error('Error:', error);
        });
    }

    let lastBalance = 0;
    const transactionGroups = [...allTransactions.payments, ...allTransactions.cashCountings ?? []]
        .reduce((groups, transaction) => {
        // Extract the date from the transaction and create a Date object
        const date = new Date(transaction.date);
    
        // Get the month and year as a string (e.g., "January 2023")
        const monthYear = date.toLocaleString('default', { month: 'long', year: 'numeric' });
    
        // Find an existing group for this month and year
        let group = groups.find(g => g.monthYear === monthYear);
    
        // If the group doesn't exist, create it
        if (!group) {
            group = {
                index: date.getFullYear() * 100 + date.getMonth(),
                monthYear, 
                transactions: [],
            };
            groups.push(group);
        }

        if (transaction["balance"]) {
            lastBalance = transaction["balance"];
        } else {
            transaction["diff"] = transaction.amount_chf - lastBalance;
        }
    
        // Add the transaction to the group
        group.transactions.push(transaction);
    
        return groups;
    }, []);

    return transactionGroups
    .sort((a, b) => b.index > a.index)
    .map(group => {
        let firstPayment = null;
        const combinedRows = group.transactions
        .sort((a, b) => { 
            const dateA = new Date(a.date).getTime();
            const dateB = new Date(b.date).getTime();
            if (dateA == dateB) {
                return b.id - a.id;
            }
            return dateB - dateA;
        })
        .map(transaction => 
        {
            const transactionDate = new Date(transaction.date);
            if (!transaction["balance"]) {
                return (
                    <tr className='cash-checking'>
                        <td>
                            <button onClick={() => removeCashCounting(transaction.id)}>{t('delete')}</button>
                        </td>
                        <td>{toDisplayDateTime(transactionDate)}</td>
                        <td>{t('cash-checking')}</td>
                        <td></td>
                        <td className='right'>{transaction["diff"] == 0 ?
                            (
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    stroke="currentColor"
                                    strokeWidth="2"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    width="20"
                                    height="20"
                                >
                                    <circle cx="12" cy="12" r="10" />
                                    <polyline points="9 12 12 15 16 9" />
                                </svg>
                            ) : t('shortfall') + ": " + (transaction["diff"] > 0 ? "+" + transaction["diff"] : transaction["diff"])}
                        </td>
                        <td className='right'>{transaction.amount_chf}</td>
                    </tr>
                )
            }

            firstPayment = new Date(group.transactions[0].date);
            lastBalance = transaction["balance"];
            const userDisplayName = getUserDisplay(transaction);
            const aquiredUserDisplayName = getAquiredCustomerDisplay(transaction);
            return (
                <tr key={"transaction-id-" + transaction.id}>
                    <td>{specificAction(transaction)}</td>
                    <td>{toDisplayDateTime(transactionDate)}</td>
                    <td>{specificSubject(transaction)}</td>
                    <td>
                        <ul className='accounting-context'>
                            {transaction['voucher_id'] ? 
                                <li>{t('voucher-no') + " V" + transaction['voucher_id'].toString().padStart(6, '0')}</li> : 
                                null
                            }
                            {userDisplayName ? (
                                <li>
                                    {transaction["booking_id"] ? t('cash-from-booking-with') : t('payment-by')}: {userDisplayName}
                                </li>
                            ) : null}
                            {aquiredUserDisplayName ? (
                                <li>
                                    {t('cash-for-acquisition')}: {aquiredUserDisplayName}
                                </li>
                            ) : null}
                            {transaction.transaction_text ? (
                                <li>
                                    {t('payment-transaction-text')}: {transaction.transaction_text}
                                </li>
                            ) : null}
                        </ul>
                    </td>
                    <td className='right'>{transaction.amount_chf > 0 ? '+' : ''}{transaction.amount_chf}</td>
                    <td className='right'>{transaction.balance}</td>
                </tr>
            )
        });
        
        return (
            <div className='accounting-container' key={"month-year-" + group.monthYear}>
                <h4>{group.monthYear}</h4>
                <table className='accounting-table'>
                    <thead>
                        <tr>
                            <th></th>
                            <th>{t('date')}</th>
                            <th>{t('subject')}</th>
                            <th>{t('context-info')}</th>
                            <th>{t('amount')} [CHF]</th>
                            <th>{t('balance')} [CHF]</th>
                        </tr>
                    </thead>
                    <tbody>{combinedRows}</tbody>
                </table>
                {firstPayment ? (
                    <a className='link' target='blank' href={'/api/pdf/' + specificAccountingPdf + '.php?year=' + firstPayment.getFullYear() + "&month=" + (firstPayment.getMonth() + 1)}>
                        {t('bookkeeping')} {group.monthYear} ({t('openPdf')})
                    </a>
                ) : null}
            </div>)
        });
}

export default Accounting;
