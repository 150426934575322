//Scripts
import { decimalHourToHourAndMinutes, decimalHourToTimeString, addMinutes, areDatesEqual, areDateTimesEqual, addMonths, addDays } from './../utils/dateTimeUtils';
import { getUserDisplay } from './../utils/businessUtils'

export function getTableData(calendarInfo, numRows, minFrom, workingWeekDays, startDate, endDate, bookingCells, sequenceToTimeFrames, timeFrameMaxDate)
{
    // Initialization
    const { therapyUnitInHour, bookings, therapist } = calendarInfo;
    const tableData = [];

    const dateTimeNow = new Date();

    // Generate rows for each time slot
    for (let i = 0; i < numRows; i++) {
        const startingHour = minFrom + i * therapyUnitInHour;
        const {hours, minutes} = decimalHourToHourAndMinutes(startingHour);

        tableData.push(
        {
            "head": decimalHourToTimeString(startingHour),
            "houreOfDay": startingHour,
            "data": [],
            "selected": false,
            "daySelected": -1,
        });

        for (let j = 0; j < workingWeekDays.length; j++)
        {
            const weekday = workingWeekDays[j];
            const currentDateTime = new Date(startDate.getFullYear(), startDate.getMonth(), startDate.getDate() + weekday.weekDay - 1, hours, minutes, 0)
            const daysTimeFrames = getDaysTimeFrames(weekday.weekDay, currentDateTime, sequenceToTimeFrames, timeFrameMaxDate);
            const fixedInBreakTime = daysTimeFrames
                .flatMap(timeFrame => timeFrame.flexBreaks)
                .some(flexBreak => 
                    flexBreak.flex_break_start + 0.5 <= startingHour && 
                    flexBreak.flex_break_start + flexBreak.preak_duration > startingHour);
            const isWorkingTime = !fixedInBreakTime && daysTimeFrames
                .filter(timeFrame => timeFrame.hour_from <= startingHour && timeFrame.hour_to > startingHour).length > 0;

            if (currentDateTime < dateTimeNow && !therapist)
            {
                tableData[i].data.push({"className": "time-unit-past", "dateTime": currentDateTime });
                continue;
            }

            if (!isWorkingTime)
            {
                tableData[i].data.push({"className": "time-unit-none", "dateTime": currentDateTime });
                continue;
            }

            const bookingsInTime = bookings
                .filter(timeFrame =>
                    areDatesEqual(timeFrame.date, currentDateTime) &&
                    timeFrame.date >= startDate &&
                    timeFrame.date <= endDate &&
                    timeFrame.hour_from <= startingHour &&
                    timeFrame.hour_to > startingHour);
            if (bookingsInTime.length > 0) {
                const booking = bookingsInTime[0]
                let className = "time-unit-booked"
                const cellData = { "dateTime": currentDateTime };
                if (!booking["booking_type"] || booking["booking_type"] == 1) {
                    cellData["content"] = getUserDisplay(booking);
                    cellData["booking_type"] = booking["booking_type"];
                } else if (booking["booking_type"] > 1) {
                    className = "time-unit-break";
                }

                if (booking["booking_comment"] && booking["booking_comment"].length > 0 && booking["booking_type"] != 1) {
                    if (cellData["content"] && cellData["content"].length > 0) {
                        cellData["content"] += " - ";
                    } else {
                        cellData["content"] = "";
                    }
                    cellData["content"] += booking["booking_comment"];
                }

                cellData["className"] = className;
                tableData[i].data.push(cellData);
            }
            else
            {
                const withFlexBreakStart = daysTimeFrames
                    .flatMap(timeFrame => timeFrame.flexBreaks)
                    .some(flexBreak => flexBreak.flex_break_start === startingHour && flexBreak.preak_duration <= 0.5);
                const withFlexBreakEnd = daysTimeFrames
                    .flatMap(timeFrame => timeFrame.flexBreaks)
                    .some(flexBreak => flexBreak.flex_break_start + flexBreak.preak_duration === startingHour && flexBreak.preak_duration <= 0.5);
                tableData[i].data.push({"className": "time-unit-free", "dateTime": currentDateTime, withFlexBreakStart, withFlexBreakEnd });
                if (bookingCells != null && areDateTimesEqual(new Date(bookingCells.top), currentDateTime))
                {
                    tableData[i].selected = true;
                    tableData.daySelected = weekday.weekDay;
                }
            }
        }
    }

    return tableData;
}

export function loadMoreWeeks(setWeeks, calendarInfo)
{
    setWeeks((prevWeeks) => {
        if (calendarInfo == null || calendarInfo.timeFrames == null || calendarInfo.timeFrames.length <= 0)
            return null;

        const newStartDate = prevWeeks != null && prevWeeks.length > 0 ?
            new Date(prevWeeks[prevWeeks.length - 1].endDate) :
            initialCalendarStartDate(calendarInfo);
        if (newStartDate == null) return;

        //Exit if end exeeds limitation
        if (!InMaxDisplayMonths(calendarInfo, newStartDate))
            return prevWeeks;

        const newEndDate = new Date(newStartDate);
        newEndDate.setDate(newStartDate.getDate() + 7);
        const isMonth = prevWeeks.length > 0 ? new Date(prevWeeks[prevWeeks.length - 1].startDate).getMonth() != newStartDate.getMonth() : true;
        var newMonthText = "";
        if (isMonth)
            newMonthText = calendarInfo.months[newStartDate.getMonth()];
        return [...prevWeeks, { startDate: newStartDate, endDate: newEndDate, newMonthText, hidden: true }];
    });
}

export function InMaxDisplayMonths(calendarInfo, date) {
    const limit = addMonths(new Date(), calendarInfo.maxMonthsToShow);
    return limit >= date;
}

//This function looks out for the next free meeting
export function selectNextFreeTimeUnit(givenCell, unitsToBook, step, cellIds, therapist)
{
    const id = givenCell.getAttribute('id');
    if (!cellIds.includes(id))
    {
        cellIds.push(id);
        unitsToBook--;
    }

    // console.log(id + " | unitsToBook=" + unitsToBook + " | step=" + step + " | " + cellIds.length);

    if (unitsToBook > 0)
    {
        const dateTime = new Date(id);
        const nextDateTime = addMinutes(dateTime, step * 60);
        const nextTag = document.getElementById(nextDateTime.toISOString());

        if (nextTag == null) {
            return { unitsToBook, givenCell };
        }

        const code = nextTag.getAttribute("data-unit-availability");
        // console.log(code);

        if (therapist && code === "with-flex-break-end") {
            return selectNextFreeTimeUnit(nextTag, unitsToBook, step, cellIds, therapist);
        }

        if (code !== "time-unit-free" && (
            step < 0 && code !== "with-flex-break-end" || 
            step > 0 && code !== "with-flex-break-start")){
            return { unitsToBook, givenCell };
        }
        return selectNextFreeTimeUnit(nextTag, unitsToBook, step, cellIds, therapist);
    }

    return { unitsToBook, givenCell };
}

export function calculateMinMaxTimes(calendarInfo) {
    const { timeFrames } = calendarInfo;
    const uniqueTimeFrameIds = collectUniqueTimeFrameIds(calendarInfo);
    let minFrom = Infinity;
    let maxTo = -Infinity;
    timeFrames.forEach((frame) => {
        if (uniqueTimeFrameIds.includes(frame.id)) {
            if (frame.hour_from < minFrom) {
                minFrom = frame.hour_from;
            }
            if (frame.hour_to > maxTo) {
                maxTo = frame.hour_to;
            }
        }
    });
    return { minFrom, maxTo };
}

export function stringFormat(format, ...args) {
    return (format + "").replace(/{(\d+)}/g, (match, index) => {
        return typeof args[index] !== 'undefined' ? args[index] : match;
    });
}

export function countNextFree(tableData, i, j, rowStep, neighborsToSearch, therapist) {
    //If no free booking space is required any more => exit
    if (neighborsToSearch <= 1)
        return neighborsToSearch;

    //Move one setep. One step is one booking-unit (usually 30 minutes)
    i = i + rowStep;

    //Exit if table border is reached
    if (i >= tableData.length || i < 0)
        return neighborsToSearch;

    //Look for next free booking-unit: call-self (recursive) if free.
    const cellData = tableData[i].data[j];
    if (cellData.className === "time-unit-free") {
        if (rowStep > 0 && (!cellData.withFlexBreakEnd || therapist)) {
            return countNextFree(tableData, i, j, rowStep, neighborsToSearch - 1, therapist);
        } else if (rowStep < 0 && (!cellData.withFlexBreakStart || therapist))
            return countNextFree(tableData, i, j, rowStep, neighborsToSearch - 1, therapist);
    }
    return neighborsToSearch
}

export function getSequenceToTimeFrames(timeFrames, weekStartDate) {
    //Filter for TimeFrames with start date and one which is before week-start-date
    timeFrames = timeFrames.filter(tiemFrame => 
        tiemFrame != null &&
        tiemFrame["plan_start_date"] &&
        tiemFrame.plan_start_date.length > 0 &&
        new Date(tiemFrame.plan_start_date) <= weekStartDate);

    //Exit if no TimeFrame remains
    if (timeFrames == null || timeFrames.length <= 0)
        return;

    //Calculate latest start date within remaining TimeFrames
    const timeFrameMaxDate = timeFrames
        .map(timeFrame => new Date(timeFrame.plan_start_date))
        .reduce((maxDate, currentDate) => maxDate > currentDate ? maxDate : currentDate);

    //Select TimeFrames with the exact latest start date
    timeFrames = timeFrames.filter(tiemFrame => areDatesEqual(new Date(tiemFrame.plan_start_date), timeFrameMaxDate))

    //Create a sequence to TimeFrames hash
    var sequenceToTimeFrames = {};
    timeFrames.forEach(timeFrame => {
        if (!sequenceToTimeFrames[timeFrame.sequence]) {
            sequenceToTimeFrames[timeFrame.sequence] = [];
        }
        sequenceToTimeFrames[timeFrame.sequence].push(timeFrame);
    });

    //Order after sequence
    sequenceToTimeFrames = sortObjectKeys(sequenceToTimeFrames);
    return { timeFrameMaxDate, sequenceToTimeFrames };
}

export function getWorkingWeekDays(weekdays, sequenceToTimeFrames) {
    const timeFramesWeekdays = Object.values(sequenceToTimeFrames).flat().map(timeFrame => timeFrame.weekday_id);
    return weekdays.filter((weekday) => timeFramesWeekdays.includes(weekday.weekDay));
}

export function calendarWeek(date) {
    const onejan = new Date(date.getFullYear(), 0, 1);
    const week = Math.ceil((((date - onejan) / 86400000) + onejan.getDay() + 1) / 7);
    return week;
}

function collectUniqueTimeFrameIds(calendarInfo) {
    const { weekdays } = calendarInfo;
    const uniqueTimeFrameIds = new Set();
    weekdays.forEach((weekday) => {
        calendarInfo.timeFrames.forEach((timeFrame) => {
            if (timeFrame.weekday_id === weekday.weekDay)
                uniqueTimeFrameIds.add(timeFrame.id);
        });
    });
    return Array.from(uniqueTimeFrameIds);
}

function initialCalendarStartDate(calendarInfo) {
    const timeFrames = calendarInfo.timeFrames.filter(timeFrame => timeFrame != null && timeFrame["plan_start_date"] && timeFrame["plan_start_date"].length > 0);    

    //Exit if no timeFrame reaches filter creiteria
    if (timeFrames.length <= 0)
        return null;
    let latestPlanStart = timeFrames
        .map(timeFrame => new Date(timeFrame.plan_start_date))
        .reduce((minDate, currentDate) => minDate < currentDate ? minDate : currentDate);
    
    //Loop until last monday of this week
    const now = new Date();
    let nextWeek = addDays(latestPlanStart, 7);
    while (nextWeek < now) {
        latestPlanStart = nextWeek;
        nextWeek = addDays(latestPlanStart, 7);
    }

    return latestPlanStart;
}

function sortObjectKeys(obj) {
    const keys = Object.keys(obj);
    keys.sort();
  
    const sortedObject = {};
    keys.forEach(key => {
        sortedObject[key] = obj[key];
    });
  
    return sortedObject;
}

function getDaysTimeFrames(weekDay, currentDateTime, sequenceToTimeFrames, timeFrameMaxDate) {
    const msToDayFactor = 1000 * 60 * 60 * 24;
    const daysDiffToPlanStart = (currentDateTime - timeFrameMaxDate)  / msToDayFactor;
    const weekDiffToPlanStart = Math.trunc(daysDiffToPlanStart / 7);
    const sequences = Object.keys(sequenceToTimeFrames);
    const sequenceKey = weekDiffToPlanStart % sequences.length;
    const sequence = sequences[sequenceKey];
    return sequenceToTimeFrames[sequence].filter((timeFrame) => timeFrame.weekday_id === weekDay);
}
