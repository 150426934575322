//Scripts
import React from 'react';
import { useTranslation } from 'react-i18next';
import { getCalendarParams } from '../utils/UrlUtil';
import { toIsoDateWithoutTime, toDisplayDateWithoutTime, addDays, addMinutes, toDisplayTime, areDateTimesEqual, addHours } from '../utils/dateTimeUtils';
import { calculateMinMaxTimes, stringFormat, countNextFree, getTableData, getSequenceToTimeFrames, getWorkingWeekDays, calendarWeek } from './calendarLogic';
import arrangements from '../therapy/arrangements.js';
import { useLocation } from 'react-router-dom';
import LangLink from './../translations/LangLink.js'

function CalendarWeek({ startDate, endDate, calendarInfo, bookingCells, selectAppointmentEvent, toggleWeekEvent, hidden }) {
    const { t } = useTranslation();

    // Get parameters from URL
    const { therapyid, arrangementid } = getCalendarParams(useLocation());
    
    // Below date determins the start where users are allowed to book appointments.
    const userBookingStartTime = addHours(new Date(), calendarInfo.noUserBookingForNextHours);

    // Load arrangement
    const arrangement = arrangements.find((arrangement) => arrangement.id === parseInt(arrangementid, 10));
    const { minFrom, maxTo } = calculateMinMaxTimes(calendarInfo);
    const { therapyUnitInHour, weekdays } = calendarInfo;
    const unitsToBook = arrangement.bookingInMinutes / 60 / therapyUnitInHour;

    // Calculate the number of rows needed based on therapy unit in hours and time frames
    const numRows = (maxTo - minFrom) / therapyUnitInHour;

    // Create a table header for weekdays
    const { timeFrameMaxDate, sequenceToTimeFrames } = getSequenceToTimeFrames(calendarInfo.timeFrames, startDate);
    const workingWeekDays = getWorkingWeekDays(weekdays, sequenceToTimeFrames);
    const tableData = getTableData(calendarInfo, numRows, minFrom, workingWeekDays, startDate, endDate, bookingCells, sequenceToTimeFrames, timeFrameMaxDate);

    const weekRows = [];
    const dayIdSummaryClass = { };
    const startDateIso = startDate.toISOString();
    for (let y = 0; y < numRows; y++) {
        const row = [];

        // Add column start time
        if (!hidden)
        {
            var headClassName = "time-unit-left time-unit-text";
            if (tableData[y].selected)
            headClassName += " time-unit-text-selected";
            row.push(<td key={"date-" + startDateIso + "-" + y} className={headClassName}>{tableData[y].head}</td>);
        }

        for (let x = 0; x < workingWeekDays.length; x++)
        {
            // get cell data
            const cellData = tableData[y].data[x];
            const weekDay = workingWeekDays[x].weekDay;
            const dateString = toIsoDateWithoutTime(cellData.dateTime);
            const className = cellData.className;

            // adapt from time-unit-free to time-unit-insufficient
            if (cellData.className === "time-unit-free")
            {
                let neighborsToUse = unitsToBook;
                neighborsToUse = unitsToBook > 1 ? countNextFree(tableData, y, x, 1, neighborsToUse, calendarInfo.therapist) : neighborsToUse;
                neighborsToUse = unitsToBook > 1 ? countNextFree(tableData, y, x, -1, neighborsToUse, calendarInfo.therapist) : neighborsToUse;
                if (neighborsToUse > 1) {
                    cellData.className = "time-unit-insufficient";
                }
            }
    
            if (!hidden)
            {
                // console.log(cellData);
                var dataUnitAvailability = cellData.className;
                if (cellData.withFlexBreakEnd) {
                    dataUnitAvailability = "with-flex-break-end";
                }

                const commonAttributes = {
                    'key': "cell-" + cellData.dateTime.toISOString(), 
                    'id': cellData.dateTime.toISOString(),
                    "date": dateString,
                    "data-unit-availability": dataUnitAvailability,
                };
                const className = cellData.className;

                const simpelCasesofClassNames = [
                    "time-unit-past", 
                    "time-unit-none", 
                    "time-unit-booked", 
                    "time-unit-insufficient", 
                    "time-unit-break"
                ];
                if (simpelCasesofClassNames.includes(className)) {
                    const specificClass = cellData["content"] ? className + " with-comment" : className
                    row.push(
                        <td className={specificClass} title={t(className)} {...commonAttributes}>
                            {cellData.content}
                        </td>);
                } else if (className === "time-unit-free") {
                    var bookingButton = "";
                    var cellClass = "time-unit-free";
                    if (bookingCells != null)
                    {
                        //Set selected cell
                        if (bookingCells.cellIds.some(item => areDateTimesEqual(cellData.dateTime, new Date(item)))) {
                            cellClass += " time-unit-selected";
                        }

                        //Set top selected cell
                        var linkToBook;
                        if (areDateTimesEqual(cellData.dateTime, new Date(bookingCells.top)))
                        {
                            cellClass += " time-unit-selected-top";

                            //Add popup for top selected cell
                            var bookingButtonDisplay; 
                                linkToBook = `/book?therapyid=${therapyid}&arrangementid=${arrangement.id}&date=${cellData.dateTime.toISOString()}`;
                                const therapiEndDate = addMinutes(cellData.dateTime, arrangement.durationInMinutes);
                                bookingButtonDisplay = stringFormat(
                                    t("time-selection-booking"),
                                    toDisplayTime(cellData.dateTime),
                                    toDisplayTime(therapiEndDate));
                            bookingButton = cellData.dateTime < userBookingStartTime && !calendarInfo.therapist ? (
                                <div className='book-spontaneous'>
                                    <div>{t('book-spontaneous-instruction')} <a class='underline' href="tel:+41445937337">+41 44 593 73 37</a></div>
                                </div>
                            ) : (
                                <div className="book-link-container">
                                    <LangLink to={linkToBook}>
                                        <div className='book-link'>
                                            {t("times-" + weekdays[cellData.dateTime.getDay()].label + "-full")} {toDisplayDateWithoutTime(cellData.dateTime)} {bookingButtonDisplay}
                                        </div>
                                    </LangLink>
                                </div>
                            )
                        }

                        //Set bottom selected cell
                        if (areDateTimesEqual(cellData.dateTime, new Date(bookingCells.bottom)))
                            cellClass += " time-unit-selected-bottom";
                    }

                    //Check if it starts or / and ends with flexBreak
                    if (cellData["withFlexBreakStart"]) {
                        cellClass += " with-flex-break-start"
                    }
                    if (cellData["withFlexBreakEnd"]) {
                        cellClass += " with-flex-break-end"
                    }

                    row.push(
                        <td className={cellClass} title={t("time-unit-free")} units-to-book={unitsToBook} {...commonAttributes} onClickCapture={selectAppointmentEvent}>
                            {bookingButton}
                        </td>);
                } else
                    console.debug("unknown code | cellData.code=" + cellData.code);
            }
            
            //Summarize availability for the day
            if (dayIdSummaryClass[weekDay] === "time-unit-free")
                continue;
            if (className === "time-unit-free")
                dayIdSummaryClass[weekDay] = className;
            else if (dayIdSummaryClass[weekDay] === "time-unit-insufficient")
                continue;
            else if (className === "time-unit-insufficient")
                dayIdSummaryClass[weekDay] = className;
            else if (dayIdSummaryClass[weekDay] === "time-unit-booked" || dayIdSummaryClass[weekDay] === "time-unit-break")
                continue;
            else if (className === "time-unit-booked" || className === "time-unit-break")
                dayIdSummaryClass[weekDay] = className;
            else if (!dayIdSummaryClass.hasOwnProperty(x))
                dayIdSummaryClass[weekDay] = "time-unit-none";
        }

        if (!hidden)
            weekRows.push(<tr className='time-row' key={startDateIso + "-" + tableData[y].houreOfDay}>{row}</tr>);
    }

    // set week headers (calendar rows with the date)
    const weekdayHeaders = workingWeekDays.map((weekday) => {
        const currentDate = addDays(startDate, weekday.weekDay - 1); //startDate is always defined as monday. Because weekday-index of monday is 1, we subtract 1.
        const isoDate = toIsoDateWithoutTime(currentDate);

        if (!dayIdSummaryClass[weekday.weekDay] || dayIdSummaryClass[weekday.weekDay] === "time-unit-none") {
            return (<th key={`${startDateIso}-head-${isoDate}`}></th>)
        }

        const day = currentDate.getDate().toString().padStart(2, '0');
        const month = (currentDate.getMonth() + 1).toString().padStart(2, '0');
        // const year = currentDate.getFullYear().toString().substring(2, 4);
        const isDaySelected = tableData.daySelected === weekday.weekDay;
        var cellClass = "time-header ";
        cellClass += dayIdSummaryClass[weekday.weekDay];
        cellClass += hidden ? "" : " " + t("time-header-open");
        cellClass += isDaySelected ? " time-header-selected" : "";

        const weekHeaderRowContent = (
            <React.Fragment key={"th-fragment-" + isoDate}>
                <div className='weekday-label-short'>
                    {t("times-" + weekday.label + "-short")}
                </div>
                <div className='weekday-label-full'>
                    {t("times-" + weekday.label + "-full")}
                </div>
                <br />
                
                <div className='weekday-label-short'>
                    {currentDate.getDate()}
                    <span className='weekday-label-small'>.{currentDate.getMonth() + 1}</span>
                </div>
                <div className='weekday-label-full'>
                    {day}
                    <span className='weekday-label-small'>.{month}</span>
                </div>
            </React.Fragment>
        )

        return (
            <th
                key={`${startDateIso}-head-${isoDate}`}
                onClickCapture={dayIdSummaryClass[weekday.weekDay] === "time-unit-free" ? toggleWeekEvent : null}
                className={cellClass}
                date={isoDate} >
                {weekHeaderRowContent}
            </th>
        );
    });

    const firstHeadCellContent = hidden ? stringFormat(t("times-calendar-week"), calendarWeek(startDate)) : t("times-start-time");
    const firstHeadCellClass = hidden ? "time-unit-left time-unit-calendar-week" : "time-unit-left time-unit-info";
    const firstHeadCell = (
        <th key={"first-head-cell-" + startDateIso} className={firstHeadCellClass}>{firstHeadCellContent}</th>
    );

    return (
        <React.Fragment key={"head-row-fragment-" + startDateIso}>
            <tr key={"head-row-" + startDateIso}>
                {firstHeadCell}
                {weekdayHeaders}
            </tr>
            {weekRows}
        </React.Fragment>
    );
}

export default CalendarWeek;
